import {
  Box,
  Divider,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react"
import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/CoreUI/SEO"

interface UniformBoxProps {
  children: React.ReactNode
}

function UniformBox({ children }: UniformBoxProps): JSX.Element {
  return (
    <Box maxW="95vw" mx="auto" py="24px" w="800px">
      {children}
    </Box>
  )
}

function Terms({ location }: PageProps) {
  return (
    <Layout>
      <SEO
        meta={{
          description: `The terms of use for visitors to ${location.origin}.`,
          image: `${location.origin}/sundog-homes-logo.png`,
          imageAlt: `The Sundog Homes logo`,
        }}
        title="Terms of Use | Sundog Homes"
        url={location.href}
      />
      <VStack>
        <UniformBox>
          <Heading fontSize="5xl" mb={6} mt={18}>
            Terms of Use
          </Heading>
          <Text mb={6} mt={2}>
            The Sundog Homes Terms of Use is effective as of October 1, 2021. By
            using the Sundog Homes website and services therein you are
            signifying your acceptance of this updated policy.
          </Text>
          <Text mb={6} mt={2}>
            SundogHomes.com and the services provided through the website ("Web
            Application") will be referred to in this document as the
            "Services". The Services are wholly owned and operated by Sundog
            Homes, Inc., a developer located at 702 W. Main St., Sylva, NC
            28779. These Terms of Use ("Terms") serve as the "Agreement" between
            you the user and Sundog Homes, Inc. regarding the Services. By using
            any portion of the Services, you accept the terms of the Agreement.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Communication with Sundog Homes
          </Heading>
          <Text mb={6} mt={2}>
            You agree that Sundog Homes may communicate with you, including
            through automated systems, using the contact information associated
            with your Sundog Homes account or Sundog Homes Applications,
            including your device ID, email, mobile number, telephone, or the
            postal address you provided (if any). Please review your Account
            Settings or settings on your mobile device to control what kind of
            messages you receive from Sundog Homes. Sundog Homes may send you
            messages, including email, text messages/SMS, telephone calls, and
            direct mail, relating to your account or your use of the Services.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Equal Housing Opportunity
          </Heading>
          <Text mb={6} mt={2}>
            Sundog Homes supports equal housing opportunities throughout the
            areas we serve. Barriers to obtaining housing because of race,
            color, religion, sexual orientation, sex, gender identity, handicap,
            familial status, or national origin will not be tolerated and will
            be reported.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Limitation of Liability
          </Heading>
          <Text mb={6} mt={2}>
            Sundog Homes disclaims all liability for identity theft or any
            misuse of your personally identifiable information. Sundog Homes
            does not guarantee that the Services are free from error or will
            function without errors or interruptions. Sundog Homes disclaims all
            liability for damages that may be caused by any such errors or
            failures of the Services.
          </Text>
        </UniformBox>
      </VStack>
    </Layout>
  )
}

export default Terms
